* {
  padding: 0;
  margin: 0;
}

html,
body {
  background-color: #fafafa;
  font-family: Helvetica, Verdana, sans-serif;
  width: 100vw;
  height: 100vh;
}
